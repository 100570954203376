<template>
  <v-dialog v-if="object" persistent v-model="dialog" width="600">
    <v-card height="550">
      <v-toolbar elevation="0" outlined>
        <v-container fluid>
          <v-row>
            <div class="text-subtitle-1">编辑基本信息</div>
            <v-spacer></v-spacer>
            <v-icon @click="dialog=false">mdi-close</v-icon>
          </v-row>
        </v-container>
      </v-toolbar>
      <div class="pa-8">
        <v-row>
          <div class="text-subtitle-1">编辑基本信息</div>
          <v-spacer></v-spacer>
          <v-icon @click="dialog=false">mdi-close</v-icon>
        </v-row>
        <!-- object name -->
        <v-row>
          <v-text-field class="mb-0 mt-1 mx-3" dense outlined v-model="object.ob_name"></v-text-field>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="text-subtitle-2">周期</div>
          </v-col>
          <v-col cols="6">
            <div class="text-subtitle-2">负责人</div>
          </v-col>
        </v-row>
        <v-row>
          <!-- cycle -->
          <v-col cols="6">
            <v-btn @click="openCyclePicker" class="justify-start" outlined block color="#D3D3D3">
              <span class="black--text">{{ translateCycle(object.ob_cycle) }}</span>
              <v-spacer></v-spacer>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
            <CycleRangePicker @cancel="cyclePicker=false" @ok="changeCycle" v-if="cyclePicker" v-bind:object="changedObject"/> 
          </v-col>
          <!-- object owner -->
          <v-col cols="6">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn class="justify-start" v-bind="attrs" v-on="on" outlined block color="#D3D3D3">
                  <span class="black--text">{{ object.ob_owner | username }}</span>
                  <v-spacer></v-spacer>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <UserPicker @pick="setOwner"/>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="text-subtitle-2">可见范围</div>
          </v-col>
          <v-col cols="6">
            <div class="text-subtitle-2">类型</div>
          </v-col>
        </v-row>
        <v-row>
          <!-- object visible -->
          <v-col cols="6">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn v-bind="attrs" v-on="on" class="justify-start" outlined block color="#D3D3D3">
                  <span class="black--text">{{ visibleType[object.ob_visible_type] }}</span>
                  <v-spacer></v-spacer>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn @click="object.ob_visible_type=0" text small>全公司</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="object.ob_visible_type=1" text small>仅相关成员</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="object.ob_visible_type=2" text small>仅直接下属</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="setObjectVisible" text small>指定范围</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <!-- object type -->
          <v-col cols="6">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn v-bind="attrs" v-on="on" class="justify-start" outlined block color="#D3D3D3">
                  <span class="black--text">{{ objectType[object.ob_type] }}</span>
                  <v-spacer></v-spacer>
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn @click="object.ob_type=0" small text>公司</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="object.ob_type=1" small text>部门</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="object.ob_type=2" small text>团队</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="object.ob_type=3" small text>个人</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <!-- object parent -->
        <v-row>
          <div class="text-subtitle-2 ml-3">对齐目标</div>
        </v-row>
        <v-row>
          <v-btn class="ml-3" width="540" color="#D3D3D3" outlined>
            <div v-if="!!object.ob_parent_object" class="black--text">{{ getParent(object.ob_parent_object) }}</div>
            <div v-else>选择对齐上级目标</div>
            <v-spacer></v-spacer>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </v-row>
        <!-- buttons -->
        <v-row class="justify-end mt-12 mr-4">
          <v-btn @click="dialog=false" class="mx-1" small>取消</v-btn>
          <v-btn @click="ok" class="mx-1" small color="primary">确定</v-btn>
        </v-row>
      </div>
    </v-card>
    <UserDepartmentTeamPicker @selected="selectVisible" ref="visibleDlg" :visibleRange="object.ob_visibility_user_names+object.ob_visibility_dp_names"/>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ObjectFormDlg',
  props: ['object'],
  components: {
    CycleRangePicker: () => import('@/components/common/CycleRangePicker.vue'),
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    UserDepartmentTeamPicker: () => import('@/components/common/UserDepartmentTeamPicker.vue')
  },
  computed: {
    ...mapGetters('okr', ['okrObj']),
  },
  data() {
    return {
      dialog: false,
      cyclePicker: false,
      changedObject: null,
      visibleType: [
        '全公司',
        '仅相关成员',
        '仅直接下属',
        '指定范围'
      ],
      objectType: [
        '公司',
        '部门',
        '团队',
        '个人',
      ]
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    openCyclePicker() {
      this.changedObject = Object.assign({}, this.object);
      this.cyclePicker = true;
    },
    changeCycle() {
      this.object.ob_cycle = this.changedObject.ob_cycle;
      this.object.ob_start_date = this.changedObject.ob_start_date;
      this.object.ob_end_date = this.changedObject.ob_end_date;
      this.cyclePicker = false;
    },
    setOwner(e) {
      this.object.ob_owner = e.user.id;
    },
    setObjectVisible() {
      this.$refs.visibleDlg.openDialog();
    },
    selectVisible(param) {
      this.object.ob_visible_type = 3;
      let visible = '';
      let vshow1 = '', vshow2 = '';
      param.user.map(u => {
          visible += 'u' + u.id + ','
          vshow1 += u.employeeName + ','
      });
      param.depart.map(d => {
          visible += 'd' + d.id + ','
          vshow2 += d.departmentOaName + ','
      });
      this.object.ob_visibility_user_names = vshow1;
      this.object.ob_visibility_dp_names = vshow2;
    },
    getParent(id) {
      const index = this.okrObj.findIndex(ob => ob.ob_id == id);
      if(index !== -1) {
        return this.okrObj[index].ob_name;
      } else {
        return '';
      }
    },
    ok() {
      this.dialog = false;
      this.$emit('ok')
    }
  }
}
</script>
